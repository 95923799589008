<template>
  <Card :title="t('supported-platforms-apis')" :collapsable="true" :defaultCollapsed="true" >
    <AntTable
      :rowKey="record => `action-${record.platform}`"
      :columns="columns"
      :dataSource="dataSource"
      :scroll="{ x: 1600, y: 400 }"
      :pagination="false"
      size="small"
      class="action-table"
    >
      <template #bodyCell="{ text, column }">
        <template v-if="column.dataIndex === 'action'">
          <CheckIcon class="action-icon check" v-if="text" />
          <CloseIcon class="action-icon" v-else />
        </template>
      </template>
    </AntTable>
  </Card>
</template>

<script>
import { inject, computed } from 'vue'

import { Table as AntTable } from 'ant-design-vue'

import CheckIcon from '@ant-design/icons-vue/lib/icons/CheckOutlined'
import CloseIcon from '@ant-design/icons-vue/lib/icons/CloseOutlined'

import { PLATFORM_LIST, ACTION_LIST } from '@/constants/singleWalletTesting'
import Card from './Card'

export default {
  name: 'Instruction',
  components: {
    AntTable,
    Card,
    CheckIcon,
    CloseIcon,
  },
  setup() {
    const t = inject('t')

    const dataSource = PLATFORM_LIST.map(({ name, actions }) => {
      const result = { platform: name }
      ACTION_LIST.forEach((action) => { result[action.id] = actions.includes(action.id) })
      return result
    })

    const columns = computed(() => {
      const result = ACTION_LIST.map(({ id, name }) => ({
        title: name,
        dataIndex: id,
        key: id,
        align: 'center',
        width: 6 * id.length + 16,
      }))

      result.unshift({
        title: t('platform'),
        dataIndex: 'platform',
        key: 'platform',
        fixed: 'left',
        width: 100,
      })

      return result
    })

    return {
      t,
      dataSource,
      columns,
    }
  },
}
</script>

<style>
.anticon.action-icon {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
}

.anticon.action-icon.check {
  color: #52c41a;
}
</style>
