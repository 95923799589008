import PlatformActions from './PlatformActions'

// 賠率種類：
// DECIMAL [+] >= 1
// MALAY [+/-]
// HONGKONG [+]
// AMERICAN [+/-] >= 100
// INDONESIAN [+/-] >= 1

// MONEYLINE(1x2) (獨贏盤) 只支持 DECIMAL, AMERICAN, 下注 MY [+], HK [+], IND [+] oddsFormat 會變成 DECIMAL
// SPREAD(HDP) (讓分盤) 及 TOTAL(O/U) (兩隊得分總和) 支持所有 DEC, US, MY, HK, IND
// 登入 IDR 玩家僅支持 IND 賠率選擇，其餘幣別玩家賠率可選擇 DEC, MY, HK, US

// 因 HALF_WON_HALF_PUSHED 僅會出現在讓分盤 +-0.25 或 +-0.75，
// 僅有以下 2 個測項的 handicap = -0.25
// "Bet > AdjustBet > Settle Win > Resettle Win (winAmount +)"
// "Bet > AdjustBet > Settle Win > Resettle Win (winAmount -)"
// 其餘情境 handicap = 0 (因讓分盤 0.0 才會發生 settle tie 退回全部本金的情況)

// HALF_WON_HALF_PUSH：
// 這是指 “一半贏、一半退回”。這種情況通常出現在讓分盤為 0.25球（或 -0.25）或 0.75球（或 -0.75）的情況下

// 解釋：
// 假設你投注了 A隊 -0.25（即A隊讓B隊0.25球），當比賽結束後，假設 A隊以1球贏，那麼你的投注會有以下情況：
// 一半的賭金會視為“贏”（因為A隊贏1球超過了0.5球的讓分），
// 另一半的賭金會被“退回”（因為0.25盤沒有完全超過1球，所以相當於“平局”處理）
// 具體例子：
// 假設你投注 A隊 -0.25 100元（即每單位賭注為100元），比賽結果為 A隊贏1球：

// 你的100元會被分成兩部分：
// 一部分 50元 按 0.5球的盤口計算會贏，會獲得賠款
// 另一部分 50元 會退回（因為0.25盤本來沒有完全達到1球）
// 這樣的情況稱為 "half won, half pushed"，即一部分獲勝、一部分退還

// HALF_LOST_HALF_PUSH：
// 這是指 “一半輸、一半退回”。這種情況同樣出現在 0.25球（或 -0.25）或 0.75球（或 -0.75）這類讓分盤中，但通常是當比賽結果對你的投注不利時。

// 解釋：
// 假設你投注了 A隊 -0.75（即A隊讓B隊0.75球），當比賽結束後，假設 A隊以1球贏，那麼你的投注會有以下情況：
// 一半的賭金會被 退回（因為A隊贏1球，相當於對應0.5球的部分），
// 另一半的賭金會輸掉（因為A隊並未贏超過1球，這部分對應0.75球的部分仍然沒有完全達到）
// 具體例子：
// 假設你投注 A隊 -0.75 100元，比賽結果為 A隊贏1球：

// 你的100元會被分成兩部分：
// 一部分 50元 會被退回（因為A隊贏1球，超過了0.5球的讓分）
// 另一部分 50元 會 輸掉（因為A隊贏1球沒有超過0.75球的讓分）
// 這樣的情況稱為 "half lost, half pushed"，即一部分賭注輸掉、一部分賭注退還

export default class PINNACLEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SPORTS',
      gameCode: 'PINNACLE-SPORTS-001',
      platform: 'PINNACLE',
    }

    this.gameName = 'Sportsbook'

    this.constantGameInfo = {
      legId: '9a46b3c6-411a-4eef-8900-8b7fb268f53a',
      sportId: 29,
      sportName: 'Soccer',
      betType: 'SPREAD',
      handicap: 0,
      leagueId: 2436,
      leagueName: 'Italy - Serie A',
      eventId: 1599734755,
      eventName: 'Lecce vs Empoli',
      // oddsFormat: 'AMERICAN',
      // odds: 137,
      // eventStartDate: '2024-11-08T15:45:00-04:00',
      live: false,
      settledDate: null, // ex: '2024-11-07T23:54:09-04:00' (only have value in settle action)
      selection: 'Lecce',
      homeTeam: 'Lecce',
      awayTeam: 'Empoli',
      countryId: 53419,
      countryName: 'Italy',
      marketId: 7037135,
      marketName: 'Handicap Match',
      liveScore: null, // ex: '1-1' (only have value when live = true)
    }

    this.oddsFormatMap = {
      1: 'MALAY',
      2: 'HONGKONG',
      3: 'DECIMAL',
      4: 'INDONESIAN',
      5: 'AMERICAN',
    }

    this.getParlayGameInfo = ({ odds, matchDateTime, legStatus }) => [
      {
        legId: '81f52c6c-e1d9-41fb-b119-f637be87423e',
        sportId: 29,
        sportName: 'Soccer',
        betType: 'MONEYLINE',
        handicap: null,
        leagueId: 2395,
        leagueName: 'Romania - Liga 1',
        eventId: 1600228732,
        eventName: 'Gloria Buzau vs Petrolul Ploiesti',
        oddsFormat: 'DECIMAL',
        odds: odds[0],
        eventStartDate: matchDateTime,
        live: false,
        settledDate: null,
        selection: 'Petrolul Ploiesti',
        homeTeam: 'Gloria Buzau',
        awayTeam: 'Petrolul Ploiesti',
        countryId: 53448,
        countryName: 'Romania',
        marketId: 7087132,
        marketName: '1X2 Match',
        liveScore: null,
        legStatus,
      },
      {
        legId: 'c16c4854-70a6-41d8-be49-f9412f744111',
        sportId: 29,
        sportName: 'Soccer',
        betType: 'TOTAL_POINTS',
        handicap: 2.5,
        leagueId: 9154,
        leagueName: 'Vietnam - V League',
        eventId: 1600463818,
        eventName: 'Ho Chi Minh City vs Nam Dinh',
        oddsFormat: 'DECIMAL',
        odds: odds[1],
        eventStartDate: matchDateTime,
        live: false,
        settledDate: null,
        selection: 'UNDER',
        homeTeam: 'Ho Chi Minh City',
        awayTeam: 'Nam Dinh',
        countryId: 53457,
        countryName: 'Vietnam',
        marketId: 7219023,
        marketName: 'Over Under Match',
        liveScore: null,
        legStatus,
      },
      {
        legId: 'c3b01c82-508d-4eb0-af4a-a1982807bec3',
        sportId: 29,
        sportName: 'Soccer',
        betType: 'SPREAD',
        handicap: 0.5,
        leagueId: 2432,
        leagueName: 'Spain - Segunda Division',
        eventId: 1600187808,
        eventName: 'Deportivo La Coruna vs Eibar',
        oddsFormat: 'DECIMAL',
        odds: odds[2],
        eventStartDate: matchDateTime,
        live: false,
        settledDate: null,
        selection: 'Eibar',
        homeTeam: 'Deportivo La Coruna',
        awayTeam: 'Eibar',
        countryId: 53427,
        countryName: 'Spain',
        marketId: 7080095,
        marketName: 'Handicap Match',
        liveScore: null,
        legStatus,
      },
    ]
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
      oddsType = 2,
      odds,
      isParlay,
      matchDateTime,
      handicap,
    }) => {
      const betType = isParlay ? 'PARLAY' : 'SPREAD'

      return {
        ...this.constantTxnData,
        gameName: this.gameName,
        userId,
        platformTxId: `${txnId}`, // 7926
        roundId: `${txnId}`, // 49307808
        betType,
        currency,
        betTime, // 2024-11-09T15:33:25.000+08:00
        betAmount,
        gameInfo: {
          legs: isParlay ? this.getParlayGameInfo({ odds, matchDateTime, legStatus: 'PENDING_ACCEPTANCE' }) : [{
            ...this.constantGameInfo,
            betType,
            oddsFormat: this.oddsFormatMap[oddsType],
            odds,
            eventStartDate: matchDateTime,
            ...(handicap && { handicap }), // "Bet > AdjustBet > Settle Win > Resettle Win (winAmount +-)" handicap = -0.25
            legStatus: 'PENDING_ACCEPTANCE',
          }],
          isPremium: false,
        },
      }
    })

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getAdjustBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betAmount,
      adjustAmount,
      oddsType = 2,
      odds,
      isParlay,
      matchDateTime,
      handicap,
    }) => {
      const betType = isParlay ? 'PARLAY' : 'SPREAD'

      return {
        ...this.constantTxnData,
        gameName: this.gameName,
        userId,
        platformTxId: `${txnId}`, // 26408
        roundId: `${txnId}`, // 26408
        refPlatformTxId: null,
        settleType: 'platformTxId',
        updateTime,
        betAmount,
        adjustAmount,
        gameInfo: {
          legs: isParlay ? this.getParlayGameInfo({ odds, matchDateTime, legStatus: 'ACCEPTED' }) : [{
            ...this.constantGameInfo,
            betType,
            oddsFormat: this.oddsFormatMap[oddsType],
            odds,
            eventStartDate: matchDateTime,
            ...(handicap && { handicap }), // "Bet > AdjustBet > Settle Win > Resettle Win (winAmount +-)" handicap = -0.25
            legStatus: 'ACCEPTED',
          }],
          isPremium: false,
        },
      }
    })

    const message = {
      action: 'adjustBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      oddsType = 2,
      odds,
      isParlay,
      matchDateTime,
      handicap,
      settledStatus,
    }) => {
      const betType = isParlay ? 'PARLAY' : 'SPREAD'

      return {
        ...this.constantTxnData,
        gameName: this.gameName,
        userId,
        platformTxId: `${txnId}`, // 26408
        refPlatformTxId: null,
        settleType: 'platformTxId',
        updateTime,
        roundId: `${txnId}`, // 26408
        betType,
        betTime,
        txTime,
        turnover,
        betAmount,
        winAmount,
        gameInfo: {
          legs: isParlay ? this.getParlayGameInfo({ odds, matchDateTime, legStatus: settledStatus }) : [{
            ...this.constantGameInfo,
            betType,
            oddsFormat: this.oddsFormatMap[oddsType],
            odds,
            eventStartDate: matchDateTime,
            settledDate: updateTime,
            ...(handicap && { handicap }), // "Bet > AdjustBet > Settle Win > Resettle Win (winAmount +-)" handicap = -0.25
            legStatus: settledStatus,
          }],
          settledStatus,
        },
      }
    })

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      oddsType = 2,
      odds,
      matchDateTime,
      handicap,
      settledStatus,
      resettleTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      roundId: `${txnId}`,
      betType: 'SPREAD',
      betTime,
      txTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        legs: [{
          ...this.constantGameInfo,
          betType: 'SPREAD',
          oddsFormat: this.oddsFormatMap[oddsType],
          odds,
          eventStartDate: matchDateTime,
          settledDate: updateTime,
          ...(handicap && { handicap }), // "Bet > AdjustBet > Settle Win > Resettle Win (winAmount +-)" handicap = -0.25
          legStatus: settledStatus,
        }],
        settledStatus,
        resettleTime,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      oddsType = 2,
      odds,
      matchDateTime,
      handicap,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${txnId}`,
      gameInfo: {
        legs: [{
          ...this.constantGameInfo,
          betType: 'SPREAD',
          oddsFormat: this.oddsFormatMap[oddsType],
          odds,
          eventStartDate: matchDateTime,
          ...(handicap && { handicap }), // "Bet > AdjustBet > Settle Win > Resettle Win (winAmount +-)" handicap = -0.25
          legStatus: 'PENDING_ACCEPTANCE',
        }],
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
