export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Tie',
    functionName: 'bet_settleTie',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > VoidBet',
    functionName: 'bet_voidBet',
  },
  {
    name: 'Bet > VoidBet 5 times',
    functionName: 'bet_voidBet5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Tip',
    functionName: 'tip',
  },
  {
    name: 'Tip > CancelTip',
    functionName: 'tip_cancelTip',
  },
  {
    name: 'Tip > CancelTip 5 times',
    functionName: 'tip_cancelTip5Times',
  },
  {
    name: 'CancelTip only',
    functionName: 'cancelTipOnly',
  },
  {
    name: 'CancelTip > Tip',
    functionName: 'cancelTip_tip',
  },
  {
    name: 'Multi Bets with 3 Players - (Roulette) Bet > Settle',
    functionName: 'multiPlayers_multiBets_settle',
  },
  {
    name: 'Multi Bets with 3 Players - (Roulette) Bet > VoidBet',
    functionName: 'multiPlayers_multiBets_voidBet',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
]
