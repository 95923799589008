<template>
  <Card :title="t('test-report')" id="test-report">
    <!-- <div>{{ expandedScenarioRowKeys }}</div>
    <div>{{ expandedRequestRowKeys }}</div>
    <div>{{ expandedCustomRowKeys }}</div> -->

    <div v-show="isTesting" class="progress">
      <AntProgress
        type="circle"
        :stroke-color="{
          '0%': '#108ee9',
          '100%': '#87d068',
        }"
        :percent="testedScenarioCounts / scenarioCounts * 100"
      >
        <template #format>
          <div class="progress-finished-text">{{ t('finished') }}</div>
          <div class="progress-count-text">{{ testedScenarioCounts }} / {{ scenarioCounts }}</div>
          <div class="progress-scenario-text">{{ t('test-scenario-unit') }}</div>
        </template>
      </AntProgress>
    </div>

    <div v-show="!isTesting">
      <TestResult
        :platformData="platformData"
        :testReportData="testReportData"
        :errorData="errorData"
      />

      <div class="test-report" v-show="testReportData.length">
        <AntRow :gutter="16" justify="end">
          <AntCol>
            <!-- <AntButton html-type="submit" @click="handleExpandAll">
              {{ expandAll ? t('collapse-all') : t('expand-all') }}
              <ExpandIcon v-show="!expandAll" />
              <CollapseIcon v-show="expandAll" />
            </AntButton> -->
          </AntCol>
          <AntCol>
            <AntButton html-type="submit" @click="handleExportPDF">
              {{ t('export-to-pdf') }}<DownloadIcon />
            </AntButton>
          </AntCol>
        </AntRow>
        <TestScenarioTable
          :data="testReportData.map((d, index) => ({ index, ...d }))"
          :expandedRowKeys="expandedRowKeys"
        >
          <template #expandedRowRender="{ record, index: scenarioIndex }">
            <TestRequestTable
              :record="record"
              :scenarioIndex="scenarioIndex"
              :expandedRowKeys="expandedRowKeys"
            >
              <template #expandedRowRender="{ record, index: requestIndex }">
                <TestCustomTable
                  :record="record"
                  :scenarioIndex="scenarioIndex"
                  :requestIndex="requestIndex"
                  :expandedRowKeys="expandedRowKeys"
                >
                  <template #expandedRowRender="{ record }">
                    <TestCaseTable
                      :record="record"
                    />
                  </template>
                </TestCustomTable>
              </template>
            </TestRequestTable>
          </template>
        </TestScenarioTable>
      </div>
    </div>

  </Card>

  <ExportableTable
    :platformData="platformData"
    :formData="formData"
    :testReportData="testReportData"
    :errorData="errorData"
  />
</template>

<script>
import {
  // ref,
  reactive,
  inject,
  // watch,
  // toRaw,
  toRefs,
  // computed,
} from 'vue'

import {
  Row as AntRow,
  Col as AntCol,
  Button as AntButton,
  Progress as AntProgress,
} from 'ant-design-vue'

import DownloadIcon from '@ant-design/icons-vue/lib/icons/DownloadOutlined'
// import ExpandIcon from '@ant-design/icons-vue/lib/icons/ArrowsAltOutlined'
// import ShrinkOutlined from '@ant-design/icons-vue/lib/icons/CollapseIcon'

import Card from '../Card'

import TestResult from './TestResult'
import TestScenarioTable from './TestScenarioTable'
import TestRequestTable from './TestRequestTable'
import TestCustomTable from './TestCustomTable'
import TestCaseTable from './TestCaseTable'
import ExportableTable from './ExportableTable'

import exportPDF from './utils/exportPDF'

export default {
  name: 'TestReport',
  components: {
    AntRow,
    AntCol,
    AntButton,
    AntProgress,
    Card,
    TestResult,
    TestScenarioTable,
    TestRequestTable,
    TestCustomTable,
    TestCaseTable,
    ExportableTable,
    DownloadIcon,
    // ExpandIcon,
    // CollapseIcon,
  },
  props: {
    testData: Object,
  },
  setup(props) {
    const t = inject('t')
    const testData = reactive(props.testData)
    const expandedRowKeys = reactive({
      scenario: [],
      request: [],
      custom: [],
    })

    // const expandedScenarioRowKeys = ref([])
    // const expandedRequestRowKeys = ref([])
    // const expandedCustomRowKeys = ref([])

    // let { testReportData } = toRefs(testData)
    // watch(testReportData, newVal => testReportData.value = newVal)
    // testReportData = toRaw(testReportData.value)

    // const expandAll = ref(false)

    // const expandedAllScenarioRowKeys = computed(() => [...Array(testReportData.length).keys()].map(i => `scenario${i}`))

    // /* eslint-disable implicit-arrow-linebreak */
    // const expandedAllRequestRowKeys = computed(() =>
    //   expandedAllScenarioRowKeys.value.map((scenarioKey, scenarioIndex) =>
    //     testReportData[scenarioIndex].requests.map((_, requestIndex) => `${scenarioKey}-request${requestIndex}`)).flat())
    // /* eslint-enable */

    // const expandedRequestRowKeys = ref(expandedAllRequestRowKeys.value)

    // const expandedAllCustomRowKeys = computed(() => expandedRequestRowKeys.value.map(requestKey => `${requestKey}-custom2`))

    // const expandedCustomRowKeys = ref(expandedAllCustomRowKeys.value)

    // const onExpandedScenarioRowsChange = rowKeys => expandedRowKeys.scenario = rowKeys
    // const onExpandedRequestRowsChange = rowKeys => expandedRowKeys.request = rowKeys
    // const onExpandedCustomRowsChange = rowKeys => expandedRowKeys.custom = rowKeys

    // const handleExpandAll = () => {
    //   expandAll.value = !expandAll.value
    //   onExpandedScenarioRowsChange(expandAll.value ? expandedAllScenarioRowKeys.value : [])
    //   onExpandedRequestRowsChange(expandedAllRequestRowKeys.value)
    //   onExpandedCustomRowsChange(expandedAllCustomRowKeys.value)
    // }

    const handleExportPDF = () => exportPDF(t, testData.platformData.name)

    return {
      t,
      ...toRefs(testData),
      // expandAll,
      expandedRowKeys,
      // expandedScenarioRowKeys,
      // expandedRequestRowKeys,
      // expandedCustomRowKeys,
      // handleExpandAll,
      handleExportPDF,
      // onExpandedScenarioRowsChange,
      // onExpandedRequestRowsChange,
      // onExpandedCustomRowsChange,
    }
  },
}
</script>

<style>
.test-report {
  background: #fafafa;
  padding: 28px 32px;
}

.ant-table-column-title {
  font-weight: 800;
}

.progress {
  width: 100%;
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-finished-text {
  font-size: 12px;
  color: #52c41a;
  margin-bottom: 12px;
}

.progress-count-text {
  margin-bottom: 8px;
}

.progress-scenario-text {
  color: #666;
  font-size: 12px;
  margin-bottom: 4px;
}
</style>
