import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class SEXYBCRTTestScenarios extends PlatformTestScenarios {
  async multiPlayers_multiBets_settle() {
    await this.setTestScenario(
      async () => {
        const playerIndexes = []

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          playerIndexes.push(playerIndex)
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.multiBets({ playerIndexes: [playerIndex] })
        }

        if (this.requests.actions.summaryReqId) {
          for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
            await this.requests.multiSettle({ playerIndexes: [playerIndex] })
            await this.requests.getBalance({ action: 'settle', playerIndexes: [playerIndex] })
          }
        } else {
          await this.requests.multiSettle({ playerIndexes })

          for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
            await this.requests.getBalance({ action: 'settle', playerIndexes: [playerIndex] })
          }
        }
      },
    )
  }

  async multiPlayers_multiBets_voidBet() {
    await this.setTestScenario(
      async () => {
        const playerIndexes = []

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          playerIndexes.push(playerIndex)
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
          await this.requests.multiBets({ playerIndexes: [playerIndex] })
        }

        if (this.requests.actions.summaryReqId) {
          for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
            await this.requests.multiVoidBets({ playerIndexes: [playerIndex] })
            await this.requests.getBalance({ action: 'voidBet', playerIndexes: [playerIndex] })
          }
        } else {
          await this.requests.multiVoidBets({ playerIndexes })

          for (let playerIndex = 0; playerIndex < 3; playerIndex += 1) {
            await this.requests.getBalance({ action: 'voidBet', playerIndexes: [playerIndex] })
          }
        }
      },
    )
  }

  async multiPlayers_multiBets_bet1_bet2_bet3_settle_unsettle_settle() {
    await this.setTestScenario(
      async () => {
        const PLAYER_LENGTH = 3
        const BET_LENGTH = 3
        const BET_TYPES = ['Banker', 'Player', 'Tie']

        const playerIndexes = Array.from({ length: PLAYER_LENGTH }).map((_, i) => i)
        const betIndexes = Array.from({ length: BET_LENGTH }).map((_, i) => i)

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
            await this.requests.bet({
              playerIndexes: [playerIndex],
              betIndexes: [betIndex],
              data: {
                betType: BET_TYPES[betIndex],
              },
            })
          }
        }

        await this.requests.settle({
          playerIndexes,
          betIndexes,
          settleInfo: { winner: 'Banker' },
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }

        await this.requests.unsettle({
          playerIndexes,
          betIndexes,
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'unsettle',
            playerIndexes: [playerIndex],
          })
        }

        await this.requests.settle({
          playerIndexes,
          betIndexes,
          settleInfo: { winner: 'Player' },
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }

  async multiPlayers_multiBets_bet1_bet2_bet3_settle_resettle() {
    await this.setTestScenario(
      async () => {
        const PLAYER_LENGTH = 3
        const BET_LENGTH = 3
        const BET_TYPES = ['Banker', 'Player', 'Tie']

        const playerIndexes = Array.from({ length: PLAYER_LENGTH }).map((_, i) => i)
        const betIndexes = Array.from({ length: BET_LENGTH }).map((_, i) => i)

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({ playerIndexes: [playerIndex] })
        }

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
            await this.requests.bet({
              playerIndexes: [playerIndex],
              betIndexes: [betIndex],
              data: {
                betType: BET_TYPES[betIndex],
              },
            })
          }
        }

        await this.requests.settle({
          playerIndexes,
          betIndexes,
          settleInfo: { winner: 'Banker' },
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'settle',
            playerIndexes: [playerIndex],
          })
        }

        await this.requests.resettle({
          playerIndexes,
          betIndexes,
          settleInfo: { winner: 'Player' },
        })

        for (let playerIndex = 0; playerIndex < PLAYER_LENGTH; playerIndex += 1) {
          await this.requests.getBalance({
            action: 'resettle',
            playerIndexes: [playerIndex],
          })
        }
      },
    )
  }

  async bet_adjustAmt1_adjustAmt2_adjustAmt3_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.betOneDay({
          data: {
            aBack: { odds: 1.98 },
          },
        })

        await this.requests.adjustAmt({
          betIndexes: [0],
          data: {
            aBack: { odds: 1.72 },
          },
        })

        await this.requests.adjustAmt({
          betIndexes: [1],
          data: {
            aLay: { odds: 1.52 },
          },
        })

        await this.requests.adjustAmt({
          betIndexes: [2],
          data: {
            bBack: { odds: 1.22 },
          },
        })

        await this.requests.settleOneDay({ settleInfo: { winner: 'PLAYER A' } })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustAmt1_adjustAmt2_cancelAdjustAmt2_adjustAmt3_cancelAdjustAmt3_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.betOneDay({
          data: {
            aBack: { odds: 1.56 },
          },
        })

        await this.requests.adjustAmt({
          betIndexes: [0],
          data: {
            aBack: { odds: 1.46 },
          },
        })

        await this.requests.adjustAmt({
          betIndexes: [1],
          data: {
            aBack: { odds: 1.67 },
          },
        })

        await this.requests.cancelAdjustAmt({
          betIndexes: [1],
        })

        await this.requests.adjustAmt({
          betIndexes: [2],
          data: {
            bBack: { odds: 1.11 },
          },
        })

        await this.requests.cancelAdjustAmt({
          betIndexes: [2],
        })

        await this.requests.settleOneDay({ settleInfo: { winner: 'PLAYER B' } })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_adjustAmt_cancelAdjustAmt5Times_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.betOneDay({
          data: {
            aBack: { odds: 1.98 },
          },
        })

        await this.requests.adjustAmt({
          data: {
            aBack: { odds: 1.69 },
          },
        })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.cancelAdjustAmt({ timesLoop })
        }

        await this.requests.settleOneDay({ settleInfo: { winner: 'PLAYER A' } })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_cancelAdjustAmtOnly_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.betOneDay({
          data: {
            aBack: { odds: 1.98 },
          },
        })

        await this.requests.cancelAdjustAmt({ options: { beforeAdjustAmt: true } })

        await this.requests.settleOneDay({ settleInfo: { winner: 'PLAYER A' } })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_cancelAdjustAmt_adjustAmt_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await this.requests.betOneDay({
          data: {
            aBack: { odds: 1.98 },
          },
        })

        await this.requests.cancelAdjustAmt({ options: { beforeAdjustAmt: true } })

        await this.requests.adjustAmt({
          data: {
            aBack: { odds: 1.69 },
          },
          options: { afterCancelAdjustAmt: true },
        })

        await this.requests.getBalance({ action: 'adjustAmtAfterCancelAdjustAmt' })

        await this.requests.settleOneDay({ settleInfo: { winner: 'PLAYER A' } })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }
}
