import { PLATFORM_LIST } from '../../../constants/singleWalletTesting'

import PlatformRequests from './PlatformRequests'
import KINGMAKERSLOTRequests from './KINGMAKERSLOTRequests'
import SEXYBCRTRequests from './SEXYBCRTRequests'
import HORSEBOOKRequests from './HORSEBOOKRequests'
import AELOTTORequests from './AELOTTORequests'
import BGRequests from './BGRequests'
import VRLOTTORequests from './VRLOTTORequests'
import AELIVECOMMRequests from './AELIVECOMMRequests'
import VIACASINORequests from './VIACASINORequests'

const platformCustomRequests = {
  SEXYBCRT: SEXYBCRTRequests,
  SEXYBCRT_ONEDAY: SEXYBCRTRequests,
  SEXYBCRT_SUMMARY: SEXYBCRTRequests,
  HORSEBOOK: HORSEBOOKRequests,
  VENUS: SEXYBCRTRequests,
  KINGMAKERSLOT: KINGMAKERSLOTRequests,
  AELOTTO: AELOTTORequests,
  BG: BGRequests,
  AELIVECOMM: AELIVECOMMRequests,
  VRLOTTO: VRLOTTORequests,
  VIACASINO: VIACASINORequests,
  SKYCASINO: SEXYBCRTRequests,
}

export default PLATFORM_LIST.reduce((acc, { id: platform }) => {
  acc[platform] = platformCustomRequests[platform] || PlatformRequests
  return acc
}, {})
