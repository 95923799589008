import PlatformTestScenarios from './PlatformTestScenarios'

/* eslint-disable camelcase, no-await-in-loop */
export default class JOKERTestScenarios extends PlatformTestScenarios {
  async betNSettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.betNSettleWin({ data: { betAmount: 0 } })
        await this.requests.getBalance({ action: 'betNSettle' })
      },
    )
  }

  async betNSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.betNSettleWin({ timesLoop, data: { betAmount: 0 } })
        }
        await this.requests.getBalance({ action: 'betNSettle' })
      },
    )
  }

  async asyncBetNSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        await Promise.all(Array.from({ length: 5 }).map(async (_, timesLoop) => {
          await this.requests.betNSettleWin({
            timesLoop,
            data: { betAmount: 0 },
            options: { isAsync: true },
          })
          return true
        }))

        await this.requests.getBalance({ action: 'asyncBetNSettle' })
      },
    )
  }
}
