import PlatformActions from './PlatformActions'

export default class SPADEEGAMEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'SG-EGAME-003',
      platform: 'SPADE',
    }

    this.gameName = 'Space Crasher'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 20211223150434130078392
      roundId: txnId, // 20211223150434130078392
      betType: null,
      currency,
      betTime, // 2021-12-23T15:04:34.000+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 20220408131554335098700
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2022-04-08T13:15:54.480+08:00
      roundId: txnId, // 20220408131554472098714
      betType: null,
      betTime, // 2022-04-08T13:15:54.000+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 20220408131554335098700
      roundId: txnId,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
