import SEXYBCRTActions from './SEXYBCRTActions'
import getRandomNumberByDigit from '../../../utils/getRandomNumberByDigit'

export default class SEXYBCRTSUMMARYActions extends SEXYBCRTActions {
  constructor(...args) {
    super(...args)

    this.summaryReqId = getRandomNumberByDigit(6)
  }
}

// SUMMARY:
// bet, cancelBet, voidBet, settle, tip, cancelTip 需帶 requestId, refId
// bet, settle, tip 需帶 amount
// refId:
//   bet / voidBet / settle: userIdPrefix + userId + roundTxId
//   tip: userIdPrefix + userId + platformTxId
//   cancelBet / cancelTip: bet / tip requestId
