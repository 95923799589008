import moment from 'moment'
import PlatformRequests, { getRequestName } from './PlatformRequests'

import { updatePlatform } from '../../../api/platform'

import {
  ALL_BET_TYPE_WINNERS,
  ALL_BET_TYPE_ODDS,
} from './constants/roulette'

export default class SEXYBCRTRequests extends PlatformRequests {
  // eslint-disable-next-line class-methods-use-this
  getSettleDataBySettleResult({
    action = 'settle',
    settleResult,
    resettleResult,
    resettleAmountChange,
  }) {
    let data = {}

    const settleResultData = {
      win: {
        odds: 0.95,
        status: 'WIN',
        winner: 'BANKER',
      },
      lose: {
        odds: -1,
        status: 'LOSE',
        winner: 'PLAYER',
      },
      tie: {
        odds: 0,
        status: 'TIE',
        winner: 'TIE',
      },
    }

    if (action === 'settle') {
      data = { ...settleResultData[settleResult] }

      const resultLookup = {
        win: ['10', '11', '13', '03', '07', '06'],
        lose: ['03', '07', '06', '10', '11', '13'],
        tie: ['10', '11', '13', '03', '07', '10'],
      }

      if (resettleAmountChange) {
        data = {
          ...data,
          betType: 'BankerBonus',
          odds: 3,
        }
      }

      data.result = resultLookup[settleResult]
    } else {
      data = { ...settleResultData[resettleResult] }

      const resettleResultLookup = {
        win: {
          win: ['10', '11', '13', '03', '07', '07'],
          lose: ['09', '11', '13', '03', '07', '06'],
          tie: ['10', '11', '13', '03', '07', '10'],
        },
        lose: {
          win: ['03', '07', '06', '09', '11', '13'],
          lose: ['03', '07', '07', '10', '11', '13'],
          tie: ['03', '07', '10', '10', '11', '13'],
        },
        tie: {
          win: ['10', '11', '13', '03', '07', '06'],
          lose: ['09', '11', '13', '03', '07', '10'],
          tie: ['10', '11', '13', '03', '07', '11'],
        },
      }

      if (resettleAmountChange) {
        const resettleAmountChangeData = {
          increased: {
            odds: 5,
            result: ['10', '11', '13', '03', '07', '07'],
          },
          decreased: {
            odds: 1,
            result: ['10', '11', '13', '03', '07', '05'],
          },
        }

        data = {
          ...data,
          ...resettleAmountChangeData[resettleAmountChange],
        }
      } else {
        data.result = resettleResultLookup[settleResult][resettleResult]
      }
    }

    const SUITS = ['H', 'S', 'D', 'S', 'C', 'H']
    data.result = data.result.map((r, i) => `${SUITS[i]}${r}`)

    return data
  }

  // eslint-disable-next-line class-methods-use-this
  getSettleDataByBetTypeNWinner({
    betType = 'Banker',
    winner,
    resettleWinner,
  }) {
    let data = {}

    const winOddsLookup = {
      Player: 1,
      Banker: 0.95,
      Tie: 8,
    }

    const settleResultData = {
      win: {
        odds: winOddsLookup[betType],
        status: 'WIN',
      },
      tie: {
        odds: 0,
        status: 'TIE',
      },
      lose: {
        odds: -1,
        status: 'LOSE',
      },
    }

    if (!resettleWinner) {
      let status
      if (betType === winner) {
        status = 'win'
      } else if (betType !== 'Tie' && winner === 'Tie') {
        status = 'tie'
      } else {
        status = 'lose'
      }

      data = { ...settleResultData[status] }

      const resultLookup = {
        Player: ['03', '07', '06', '10', '11', '13'],
        Banker: ['10', '11', '13', '03', '07', '06'],
        Tie: ['03', '07', '10', '10', '11', '13'],
      }

      data.result = resultLookup[winner]
    } else {
      let resettleStatus
      if (betType === resettleWinner) {
        resettleStatus = 'win'
      } else if (betType !== 'Tie' && resettleWinner === 'Tie') {
        resettleStatus = 'tie'
      } else {
        resettleStatus = 'lose'
      }

      data = { ...settleResultData[resettleStatus] }

      const resettleResultLookup = {
        Player: {
          Player: ['03', '07', '07', '10', '11', '13'],
          Banker: ['03', '07', '06', '09', '11', '13'],
          Tie: ['03', '07', '10', '10', '11', '13'],
        },
        Banker: {
          Player: ['09', '11', '13', '03', '07', '06'],
          Banker: ['10', '11', '13', '03', '07', '07'],
          Tie: ['10', '11', '13', '03', '07', '10'],
        },
        Tie: {
          Player: ['09', '11', '13', '03', '07', '10'],
          Banker: ['10', '11', '13', '03', '07', '06'],
          Tie: ['10', '11', '13', '03', '07', '11'],
        },
      }

      data.result = resettleResultLookup[winner][resettleWinner]
    }

    const SUITS = ['H', 'S', 'D', 'S', 'C', 'H']
    data.result = data.result.map((r, i) => `${SUITS[i]}${r}`)
    data.winner = winner.toUpperCase()

    return data
  }

  async multiBets(args) {
    const { playerIndexes, data = {}, betIndexes = [0] } = args || {}
    const { betAmount } = this.formData

    const playerIndex = playerIndexes ? playerIndexes[0] : 0
    const [betIndex] = betIndexes

    const BET_TYPES_BY_PLAYER = process.env.NODE_ENV === 'development' ? [
      ['Red', 'Even', 'Small'],
      ['Black', 'Odd', 'Big'],
      ['Red', 'Even', 'Small'],
    ] : [
      ['Red', 'Even', 'Small', 'Dozen 2nd', 'Column 3rd', 'Triangle 0,1,2', 'Four Numbers 0,1,2,3', 'Direct 11', 'Direct 20', 'Separate 5,8', 'Line 28,29,30,31,32,33', 'Direct 36', 'Line 19,20,21,22,23,24', 'Line 13,14,15,16,17,18', 'Direct 23', 'Separate 13,16', 'Separate 3,6', 'Separate 10,11', 'Separate 11,12', 'Direct 21', 'Direct 13', 'Direct 30', 'Direct 31', 'Separate 7,8', 'Separate 11,12', 'Separate 16,19', 'Separate 25,26', 'Separate 34,35', 'Street 10,11,12', 'Street 13, 14,15', 'Street 16,17,18', 'Corner 4,5,7,8', 'Corner 7,8,10,11', 'Corner 16,17,19,20', 'Corner 19,20,22,23', 'Corner 31,32,34,35', 'Corner 32,33,35,36', 'Separate 32,33', 'Direct 15', 'Direct 18', 'Direct 19', 'Direct 28', 'Line 19,20,21,22,23,24', 'Line 1,2,3,4,5,6', 'Direct 27', 'Direct 28'],
      ['Black', 'Odd', 'Big', 'Dozen 2nd', 'Column 3rd', 'Triangle 0,1,2', 'Four Numbers 0,1,2,3', 'Direct 11', 'Direct 20', 'Separate 5,8', 'Direct 6', 'Direct 16', 'Direct 22', 'Direct 36', 'Direct 26', 'Direct 30', 'Direct 32', 'Separate 13,16', 'Triangle 0,2,3', 'Separate 11,12', 'Separate 14,17', 'Separate 22,25', 'Separate 23,26', 'Separate 29,30', 'Separate 26,29', 'Separate 28,31', 'Separate 31,34', 'Separate 32,33', 'Street 25,26,27', 'Street 34,35,36', 'Street 16,17,18', 'Street 19,20,21', 'Corner 7,8,10,11', 'Corner 11,12,14,15', 'Corner 14,15,17,18', 'Corner 20,21,23,24', 'Corner 26,27,29,30', 'Corner 32,33,35,36', 'Direct 1', 'Direct 0', 'Direct 2', 'Direct 18', 'Separate 7,8', 'Separate 6,9', 'Line 19,20,21,22,23,24', 'Line 16,17,18,19,20,21'],
      ['Red', 'Even', 'Small', 'Dozen 2nd', 'Column 3rd', 'Triangle 0,1,2', 'Four Numbers 0,1,2,3', 'Direct 11', 'Direct 20', 'Separate 5,8', 'Direct 3', 'Direct 6', 'Direct 9', 'Direct 36', 'Direct 12', 'Direct 18', 'Direct 33', 'Separate 13,16', 'Direct 34', 'Direct 36', 'Line 28,29,30,31,32,33', 'Triangle 0,2,3', 'Line 22,23,24,25,26,27', 'Line 19,20,21,22,23,24', 'Line 7,8,9,10,11,12', 'Separate 10,13', 'Separate 19,20', 'Separate 22,25', 'Separate 30,33', 'Corner 7,8,10,11', 'Corner 10,11,13,14', 'Corner 19,20,22,23', 'Corner 29,30,32,33', 'Corner 31,32,34,35', 'Corner 5,6,8,9', 'Corner 23,24,26,27', 'Line 4,5,6,7,8,9', 'Line 10,11,12,13,14,15', 'Direct 23', 'Direct 21', 'Direct 24', 'Direct 26', 'Direct 28', 'Direct 13', 'Direct 15', 'Direct 0'],
    ]

    const betTypes = args.betTypes || BET_TYPES_BY_PLAYER[playerIndex]

    const { data: { txnId, roundId } } = await updatePlatform({
      platform: 'SEXYBCRT',
      txnId: betTypes.length,
      ...(!playerIndex && { roundId: 1 }),
    })

    let betTime
    let roundStartTime
    let denyTime

    this.betData[playerIndex] = this.betData[playerIndex] || []
    this.betData[playerIndex][betIndex] = betTypes.map((betType, txnIdx) => {
      if (!txnIdx) {
        betTime = moment().subtract(4, 'seconds').utcOffset(8).toISOString(true)
        roundStartTime = moment().subtract(10, 'seconds').utcOffset(8).toISOString(true)
        denyTime = moment(betTime).subtract(1, 'seconds').utcOffset(8).toISOString(true)
      }

      return {
        game: 'roulette',
        userId: this.formData[`userId${playerIndex + 1}`],
        roundId: !playerIndex && !betIndex ? roundId : this.betData[0][0][0].roundId,
        txnId: txnId - betTypes.length + 1 + txnIdx,
        betType,
        betAmount,
        odds: ALL_BET_TYPE_ODDS[betType],
        betTime,
        roundStartTime,
        denyTime,
        ...data,
      }
    })

    this.tests.betData = this.betData

    const res = await this.actions.bet(this.betData[playerIndex][betIndex])

    return this.requestPromise(
      {
        action: 'bet',
        requestName: getRequestName({ ...args, action: 'bet' }),
      },
      res,
      () => this.tests.bet({
        requestNo: this.requestNo,
        res: res.data,
        playerIndex,
        betIndex,
      }),
    )
  }

  async multiSettle(args) {
    const { playerIndexes } = args || {}

    const winner = 20
    const winBetTypes = Object.keys(ALL_BET_TYPE_WINNERS).filter(betType => ALL_BET_TYPE_WINNERS[betType].includes(winner))

    let updateTime
    const settleData = this.betData.map((playerBetData, playerIdx) => {
      let playerSettleData
      if (playerIndexes && playerIndexes.includes(playerIdx)) {
        const settleDataInTests = this.tests.settleData
        playerSettleData = settleDataInTests ? settleDataInTests[playerIdx] : []
      }

      if (!playerIndexes || (playerIndexes && playerIndexes.includes(playerIdx))) {
        return playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
          const {
            betAmount,
            betType,
            odds,
          } = txnBetData

          const isWin = winBetTypes.includes(betType)
          if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)

          return {
            ...txnBetData,
            odds: isWin ? odds : -1,
            winAmount: isWin ? betAmount * (1 + odds) : 0,
            winLoss: isWin ? betAmount * odds : betAmount * -1,
            turnover: betAmount * 1,
            winner,
            result: [`${winner}`],
            status: isWin ? 'WIN' : 'LOSE',
            updateTime,
          }
        }))
      }
      return playerSettleData || []
    })

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData.flat().flat())

    return this.requestPromise(
      {
        action: 'settle',
        requestName: getRequestName({ ...args, action: 'settle' }),
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  async multiVoidBets(args) {
    const { playerIndexes } = args || {}

    let updateTime
    const voidBetData = this.betData.map((playerBetData, playerIdx) => {
      let playerVoidBetData
      if (playerIndexes && playerIndexes.includes(playerIdx)) {
        const voidBetDataInTests = this.tests.voidBetData
        playerVoidBetData = voidBetDataInTests ? voidBetDataInTests[playerIdx] : []
      }

      if (!playerIndexes || (playerIndexes && playerIndexes.includes(playerIdx))) {
        return playerBetData.map((singleBetData, betIdx) => singleBetData.map((txnBetData, txnIdx) => {
          if (!playerIdx && !betIdx && !txnIdx) updateTime = moment().utcOffset(8).toISOString(true)

          return {
            ...txnBetData,
            voidType: 2,
            updateTime,
          }
        }))
      }
      return playerVoidBetData || []
    })

    this.tests.voidBetData = voidBetData

    const res = await this.actions.voidBet(voidBetData.flat().flat())

    return this.requestPromise(
      {
        action: 'voidBet',
        requestName: getRequestName({ ...args, action: 'voidBet' }),
      },
      res,
      () => this.tests.voidBet({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }

  getOnedayComputedData(onedayData) {
    return ['aBack', 'aLay', 'bBack', 'bLay'].reduce((acc, key) => {
      acc[key] = {}
      acc[key].betAmount = onedayData[key]?.odds ? (onedayData[key].betAmount || this.formData.betAmount) : 0
      acc[key].odds = onedayData[key]?.odds || 0
      return acc
    }, {})
  }

  // eslint-disable-next-line class-methods-use-this
  getOnedayForecast(computedData) {
    const {
      beforeAForecast = 0,
      beforeBForecast = 0,
      aBack,
      aLay,
      bBack,
      bLay,
    } = computedData

    const aForecast = Number(((beforeAForecast + aBack.betAmount * (aBack.odds - 1) + bLay.betAmount - bBack.betAmount - aLay.betAmount * (aLay.odds - 1))).toFixed(2))
    const bForecast = Number(((beforeBForecast + bBack.betAmount * (bBack.odds - 1) + aLay.betAmount - aBack.betAmount - bLay.betAmount * (bLay.odds - 1))).toFixed(2))

    return { aForecast, bForecast }
  }

  async betOneDay(args) {
    const { playerIndex = 0, data = {} } = args || {}

    const result = await this.betAction({
      ...args,
      action: 'bet',
      data: {
        ...data,
        betType: null,
        game: 'oneday',
      },
    })

    const computedData = this.getOnedayComputedData(this.betData[playerIndex][0][0])
    const { aForecast, bForecast } = this.getOnedayForecast(computedData)

    this.betData[playerIndex][0][0] = {
      ...this.betData[playerIndex][0][0],
      aForecast,
      bForecast,
    }

    return result
  }

  adjustAmt(args) {
    return this.betAction({
      ...args,
      action: 'adjustAmt',
    })
  }

  async setAdjustAmtData({
    playerIndex = 0,
    betIndex,
    data = {},
  }) {
    if (this.adjustIdCounter == null) this.adjustIdCounter = 1000000
    this.adjustIdCounter += 1

    this.adjustAmtData[playerIndex] = this.adjustAmtData[playerIndex] || []

    let beforeTxn
    if (this.adjustAmtData[playerIndex].length) {
      [beforeTxn] = this.adjustAmtData[playerIndex].slice(-1)[0].slice(-1)
    } else {
      [beforeTxn] = this.betData[playerIndex][betIndex]
    }

    const {
      aForecast: beforeAForecast,
      bForecast: beforeBForecast,
    } = beforeTxn

    const computedData = this.getOnedayComputedData(data)

    const { aForecast, bForecast } = this.getOnedayForecast({
      ...computedData,
      beforeAForecast,
      beforeBForecast,
    })

    const beforeExposure = Math.min(beforeAForecast, beforeBForecast)
    const exposure = Math.min(aForecast, bForecast)

    if (!this.adjustAmtData[playerIndex]) this.betData[playerIndex][0][0].adjustBetAmount = -exposure

    this.adjustAmtData[playerIndex][betIndex] = [{
      ...this.betData[playerIndex][0][0],
      ...data,
      ...computedData,
      adjustId: this.adjustIdCounter,
      aForecast,
      bForecast,
      adjustBetAmount: -exposure,
      adjustAmount: Number(((exposure - beforeExposure)).toFixed(4)),
      denyTime: moment().subtract(1, 'seconds').utcOffset(8).toISOString(true),
    }]
  }

  cancelAdjustAmt(args) {
    return this.cancelAction({
      ...args,
      action: 'cancelAdjustAmt',
    })
  }

  async settleOneDay({ settleInfo }) {
    const { winner } = settleInfo || {}
    const playerIndex = 0

    const betNAdjustAmtData = [
      ...this.betData[playerIndex][0],
      ...this.adjustAmtData[playerIndex].map(([d]) => d).filter(d => this.cancelAdjustAmtData.flat().flat().every(({ adjustId }) => d.adjustId !== adjustId)),
    ]

    const [{ aForecast, bForecast }] = betNAdjustAmtData.slice(-1)

    let winLoss
    let winAmount
    let result
    if (winner === 'PLAYER A') {
      winLoss = aForecast
      winAmount = aForecast - bForecast
      result = ['C13', 'D07', 'D13', 'D10', 'H02', 'C01']
    } else {
      winLoss = bForecast
      winAmount = bForecast - aForecast
      result = ['D10', 'H02', 'C01', 'C13', 'D07', 'D13']
    }

    winAmount = Math.max(Number(winAmount.toFixed(4)), 0)

    const isWin = winLoss > 0

    const settleData = [[{
      ...betNAdjustAmtData[0],
      betAmount: Math.abs(winLoss),
      winAmount,
      winLoss,
      turnover: Math.abs(winLoss),
      winner,
      result,
      status: isWin ? 'WIN' : 'LOSE',
      odds: 0.61, // meaningless
      updateTime: moment().utcOffset(8).toISOString(true),
    }]]

    this.tests.settleData = settleData

    const res = await this.actions.settle(settleData.flat())

    return this.requestPromise(
      {
        requestName: getRequestName({
          action: 'settle',
          // playerIndexes,
        }),
      },
      res,
      () => this.tests.settle({
        requestNo: this.requestNo,
        res: res.data,
      }),
    )
  }
}
