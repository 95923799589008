export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Tie',
    functionName: 'bet_settleTie',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  // {
  //   name: 'Bet > Settle Lose > Unsettle > Settle Win',
  //   functionName: 'bet_settleLose_unsettle_settleWin',
  // },
  // {
  //   name: 'Bet > Settle Win > Unsettle > Settle Lose',
  //   functionName: 'bet_settleWin_unsettle_settleLose',
  // },
  // {
  //   name: 'Bet > Settle Win > Unsettle > Settle Tie',
  //   functionName: 'bet_settleWin_unsettle_settleTie',
  // },
  // {
  //   name: 'Bet > Settle Tie > Unsettle > Settle Win',
  //   functionName: 'bet_settleTie_unsettle_settleWin',
  // },
  {
    name: 'Bet > VoidBet',
    functionName: 'bet_voidBet',
  },
  {
    name: 'Bet > VoidBet 5 times',
    functionName: 'bet_voidBet5Times',
  },
  // {
  //   name: 'Bet > Settle Win > Unsettle > VoidBet',
  //   functionName: 'bet_settleWin_unsettle_voidBet',
  // },
  {
    name: 'Bet > Settle Win > VoidSettle',
    functionName: 'bet_settleWin_voidSettle',
  },
  {
    name: 'Bet > Settle Lose > VoidSettle',
    functionName: 'bet_settleLose_voidSettle',
  },
  {
    name: 'Bet > Settle Tie > VoidSettle',
    functionName: 'bet_settleTie_voidSettle',
  },
  {
    name: 'Bet > Settle Win > VoidSettle 5 times',
    functionName: 'bet_settleWin_voidSettle5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  // {
  //   name: 'Negative Balance - Settle Win > Unsettle > Settle Lose',
  //   functionName: 'negativeBalance_bet1_settleWin1_bet2_unsettle1_settleLose1_settleTie2',
  // },
  // {
  //   name: 'Negative Balance - Settle Win > Unsettle > Settle Tie',
  //   functionName: 'negativeBalance_bet1_settleWin1_bet2_unsettle1_settleTie1_settleTie2',
  // },
  // {
  //   name: 'Negative Balance - Settle Win > Unsettle > VoidBet',
  //   functionName: 'negativeBalance_bet1_settleWin1_bet2_unsettle1_voidBet1_settleTie2',
  // },
  // {
  //   name: 'Multi Bets with 3 Players - Place 3 bets > Settle > Unsettle > Settle',
  //   functionName: 'multiPlayers_multiBets_bet1_bet2_bet3_settle_unsettle_settle',
  // },
  {
    name: 'Multi Bets with 3 Players - (Roulette) Bet > Settle',
    functionName: 'multiPlayers_multiBets_settle',
  },
  {
    name: 'Multi Bets with 3 Players - (Roulette) Bet > VoidBet',
    functionName: 'multiPlayers_multiBets_voidBet',
  },
  {
    name: 'Bet > Settle Win > Resettle Win',
    functionName: 'bet_settleWin_resettleWin',
  },
  {
    name: 'Bet > Settle Win > Resettle Win (winAmount +)',
    functionName: 'bet_settleWin_resettleWin_increased',
  },
  {
    name: 'Bet > Settle Win > Resettle Win (winAmount -)',
    functionName: 'bet_settleWin_resettleWin_decreased',
  },
  {
    name: 'Bet > Settle Win > Resettle Lose',
    functionName: 'bet_settleWin_resettleLose',
  },
  {
    name: 'Bet > Settle Win > Resettle Tie',
    functionName: 'bet_settleWin_resettleTie',
  },
  {
    name: 'Bet > Settle Lose > Resettle Win',
    functionName: 'bet_settleLose_resettleWin',
  },
  {
    name: 'Bet > Settle Lose > Resettle Lose',
    functionName: 'bet_settleLose_resettleLose',
  },
  {
    name: 'Bet > Settle Lose > Resettle Tie',
    functionName: 'bet_settleLose_resettleTie',
  },
  {
    name: 'Bet > Settle Tie > Resettle Win',
    functionName: 'bet_settleTie_resettleWin',
  },
  {
    name: 'Bet > Settle Tie > Resettle Lose',
    functionName: 'bet_settleTie_resettleLose',
  },
  {
    name: 'Bet > Settle Tie > Resettle Tie',
    functionName: 'bet_settleTie_resettleTie',
  },
  {
    name: 'Bet > Settle Lose > Resettle Win 5 times',
    functionName: 'bet_settleLose_resettleWin5Times',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Lose',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_resettleLose1_settleTie2',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Tie',
    functionName: 'negativeBalance_bet1_settleWin1_bet2_resettleTie1_settleTie2',
  },
  {
    name: 'Multi Bets with 3 Players - Place 3 bets > Settle > Resettle',
    functionName: 'multiPlayers_multiBets_bet1_bet2_bet3_settle_resettle',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
