import { PLATFORM_LIST } from '../../../constants/singleWalletTesting'

import SEXYBCRTActions from './SEXYBCRTActions'
import SEXYBCRTSUMMARYActions from './SEXYBCRT_SUMMARYActions'

const platformCustomActions = {
  SEXYBCRT_ONEDAY: SEXYBCRTActions,
  SEXYBCRT_SUMMARY: SEXYBCRTSUMMARYActions,
}

export default PLATFORM_LIST.reduce((acc, { id: platform }) => {
  // eslint-disable-next-line import/no-dynamic-require, global-require
  acc[platform] = platformCustomActions[platform] || require(`./${platform}Actions.js`).default
  return acc
}, {})
