export default [
  {
    name: 'Bet > AdjustBet > Settle Win (oddsFormat = MALAY ‒)',
    functionName: 'bet_adjustBet_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose (oddsFormat = HONGKONG +)',
    functionName: 'bet_adjustBet_settleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie (oddsFormat = MALAY +)',
    functionName: 'bet_adjustBet_settleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Win 5 times (oddsFormat = DECIMAL +)',
    functionName: 'bet_adjustBet_settleWin5Times',
  },
  {
    name: 'Bet > AdjustBet > (Network) Settle Win 5 times (oddsFormat = HONGKONG +)',
    functionName: 'bet_adjustBet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > CancelBet (oddsFormat = HONGKONG +)',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'CancelBet only (oddsFormat = HONGKONG +)',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet (oddsFormat = HONGKONG +)',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet (oddsFormat = HONGKONG +)',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Bet > CancelBet 5 times (oddsFormat = HONGKONG +)',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'Bet on Parlay > Settle Win (oddsFormat = DECIMAL +)',
    functionName: 'betOnParlay_settleWin',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (oddsFormat = AMERICAN ‒)',
    functionName: 'bet_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Odds Change - Bet > AdjustBet > Settle Win (oddsFormat = AMERICAN +)',
    functionName: 'betOnPositiveOdds_adjustBetForOddsChange_settleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Win (oddsFormat = HONGKONG +)',
    functionName: 'bet_adjustBet_settleWin_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Win (winAmount +) (oddsFormat = MALAY +)',
    functionName: 'bet_adjustBet_settleWin_resettleWin_increased',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Win (winAmount -) (oddsFormat = MALAY ‒)',
    functionName: 'bet_adjustBet_settleWin_resettleWin_decreased',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Lose (oddsFormat = HONGKONG +)',
    functionName: 'bet_adjustBet_settleWin_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Win > Resettle Tie (oddsFormat = DECIMAL +)',
    functionName: 'bet_adjustBet_settleWin_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Win (oddsFormat = HONGKONG +)',
    functionName: 'bet_adjustBet_settleLose_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Lose (oddsFormat = HONGKONG +)',
    functionName: 'bet_adjustBet_settleLose_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Tie (oddsFormat = INDONESIAN +)',
    functionName: 'bet_adjustBet_settleLose_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Win (oddsFormat = INDONESIAN ‒)',
    functionName: 'bet_adjustBet_settleTie_resettleWin',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Lose (oddsFormat = AMERICAN +)',
    functionName: 'bet_adjustBet_settleTie_resettleLose',
  },
  {
    name: 'Bet > AdjustBet > Settle Tie > Resettle Tie (oddsFormat = AMERICAN ‒)',
    functionName: 'bet_adjustBet_settleTie_resettleTie',
  },
  {
    name: 'Bet > AdjustBet > Settle Lose > Resettle Win 5 times (oddsFormat = HONGKONG +)',
    functionName: 'bet_adjustBet_settleLose_resettleWin5Times',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Lose (oddsFormat = MALAY ‒)',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_resettleLose1_settleTie2',
  },
  {
    name: 'Negative Balance - Settle Win > Resettle Tie (oddsFormat = DECIMAL +)',
    functionName: 'negativeBalance_bet1_adjustBet1_settleWin1_bet2_adjustBet2_resettleTie1_settleTie2',
  },
  {
    name: 'Positive Balance - Bet (oddsFormat = HONGKONG +)',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet (oddsFormat = HONGKONG +)',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet (oddsFormat = HONGKONG +)',
    functionName: 'negativeBalance_bet',
  },
]
