<template>
  <Card :title="t('required-parameters')">
    <AntForm
      ref="formRef"
      v-model:model="formState"
      :rules="formRules"
      :labelCol="{ span: 8 }"
      labelAlign="left"
      :hideRequiredMark="true"
      :autocomplete="env === 'production' ? 'off' : 'on'"
    >
      <AntCollapse v-model:activeKey="actionsCollapseActiveKey" class="actions-collapse">
        <AntCollapsePanel key="1" :header="`${platformData.name} ${t('single-wallet-api')}`">
          <ul class="action-list">
            <li v-for="action in platformData.actions" :key="action">{{ action }}</li>
          </ul>
        </AntCollapsePanel>
      </AntCollapse>

      <AntFormItem name="platform" :label="t('form-label-platform')">
        <AntSelect
          v-model:value="formState.platform"
          :options="platformOptions"
          @change="onPlatformChange"
          optionFilterProp="label"
          showSearch
        />
      </AntFormItem>

      <template
        v-for="({
          id,
          rules, // eslint-disable-line vue/no-unused-vars
          ...args
        }) in formFields"
      >
        <FormItem
          v-if="id !== 'skippedTestScenarios'"
          :key="id"
          :id="id"
          :name="id"
          :label="t(`form-label-${id}`)"
          :formState="formState"
          v-bind="args"
        />
      </template>

      <AntFormItem key="skippedTestScenarios" name="skippedTestScenarios">
        <AntCollapse v-model:activeKey="skippedScenariosCollapseActiveKey" class="skipped-scenarios-collapse">
          <AntCollapsePanel key="1" :header="t('skipped-test-scenarios')">
            <AntFormItemRest>
              <AntCheckbox
                class="skipped-scenarios-check-all"
                :checked="checkAll"
                @change="onCheckAllChange"
              >
                {{t('check-all')}}
              </AntCheckbox>
            </AntFormItemRest>
            <AntCheckboxGroup
              class="skipped-scenarios-checkbox"
              v-model:value="formState.skippedTestScenarios"
              :options="scenarioCheckOptions"
              @change="onCheckChange"
            />
          </AntCollapsePanel>
        </AntCollapse>
      </AntFormItem>

      <AntFormItem :wrapperCol="{ offset: 8 }">
        <AntButton
          type="primary"
          html-type="submit"
          :disabled="testData.isTesting"
          @click="onSubmit"
        >
          {{t('run-tests')}}<CaretRightIcon />
        </AntButton>
      </AntFormItem>
    </AntForm>

    <AntModal
      :title="t(`${insufficientBalanceBetTestState.type}-balance-bet-test`)"
      :visible="insufficientBalanceBetTestState.modalVisible"
      :closable="false"
      :maskClosable="false"
    >
      {{ t('test-purpose') }}<br>
      {{ t(`${insufficientBalanceBetTestState.type}-balance-bet-test-purpose-msg`) }}<br><br>
      {{ t('test-operation') }}<br>
      {{ t(`${insufficientBalanceBetTestState.type}-balance-bet-test-operation-msg1`) }}
      {{ ` (${formState.userId1}) ` }}
      {{ t(`${insufficientBalanceBetTestState.type}-balance-bet-test-operation-msg2`) }}
      <template #footer>
        <AntButton
          key="submit"
          type="primary"
          @click="onConfirmInsufficientBalanceBetTest"
          :loading="insufficientBalanceBetTestState.isCounting"
        >
          {{ `${t('confirm')} (${insufficientBalanceBetTestState.countDownSec})` }}
        </AntButton>
      </template>
    </AntModal>

    <AntModal
      :title="t('finished-insufficient-balance-bet-test')"
      :visible="finishedInsufficientBalanceBetTestModalVisible"
      :onOk="onConfirmFinishedInsufficientBalanceBetTest"
    >
      {{ `${t('finished-insufficient-balance-bet-test-msg1')} (${formState.userId1}) ${t('finished-insufficient-balance-bet-test-msg2')}` }}<br>
      <template #footer>
        <AntButton
          key="submit"
          type="primary"
          @click="onConfirmFinishedInsufficientBalanceBetTest"
        >
          {{ t('confirm') }}
        </AntButton>
      </template>
    </AntModal>
  </Card>
</template>

<script>
import {
  ref,
  reactive,
  inject,
  computed,
  toRaw,
  watch,
} from 'vue'

import {
  Form as AntForm,
  Select as AntSelect,
  Checkbox as AntCheckbox,
  Button as AntButton,
  Collapse as AntCollapse,
  Modal as AntModal,
} from 'ant-design-vue'

import CaretRightIcon from '@ant-design/icons-vue/lib/icons/CaretRightFilled'

import {
  PLATFORM_LIST,
  PARAMETER_FORM_FIELDS,
  DEFAULT_PLATFORM_CLIENT_DATA,
} from '@/constants/singleWalletTesting'

// import { logTestedClient } from '@/api/log'
import platformTestScenarios from '@/libs/SWA/platformTestScenarios'
import ALL_PLATFORM_TEST_SCENARIO_LIST from '@/libs/SWA/testScenarioList'

import FormItem from '../FormItem'
import Card from './Card'

export default {
  name: 'ParameterForm',
  components: {
    AntForm,
    AntFormItem: AntForm.Item,
    AntFormItemRest: AntForm.ItemRest,
    AntSelect,
    AntCheckbox,
    AntCheckboxGroup: AntCheckbox.Group,
    AntButton,
    AntCollapse,
    AntCollapsePanel: AntCollapse.Panel,
    AntModal,
    Card,
    FormItem,
    CaretRightIcon,
  },
  props: {
    testData: Object,
  },
  setup(props) {
    const t = inject('t')

    const testData = reactive(props.testData)

    const formRef = ref()
    const actionsCollapseActiveKey = ref(['1'])
    const skippedScenariosCollapseActiveKey = ref([])

    const defaultPlatform = ref('SEXYBCRT')
    const platformData = computed(() => PLATFORM_LIST.find(({ id }) => id === defaultPlatform.value))

    const getDefaultSkippedScenarios = () => {
      if (process.env.NODE_ENV === 'development') {
        const testScenarioList = ALL_PLATFORM_TEST_SCENARIO_LIST[defaultPlatform.value]

        const shouldSkippedScenarios = [
          'zeroBalance_bet',
          'negativeBalance_bet',
          'zeroBalance_bet_tip',
          'negativeBalance_bet_tip',
          'zeroBalance_betNSettleWin',
          'zeroBalance_betNSettleLose',
          'negativeBalance_betNSettleWin',
          'negativeBalance_betNSettleLose',
        ]

        const result = Object.entries(testScenarioList).reduce((acc, [scenarioNo, scenario]) => {
          if (shouldSkippedScenarios.includes(scenario.functionName)) acc.push(scenarioNo)
          return acc
        }, [])

        return result
      }
      return []
    }

    const getDefaultFormState = () => {
      const result = platformData.value.formFields.reduce((acc, field) => {
        if (field === 'skippedTestScenarios') {
          acc[field] = getDefaultSkippedScenarios()
        } else if (DEFAULT_PLATFORM_CLIENT_DATA[field] && process.env.NODE_ENV === 'development') {
          acc[field] = DEFAULT_PLATFORM_CLIENT_DATA[field]
        } else {
          const fieldData = PARAMETER_FORM_FIELDS.find(({ id }) => id === field)
          acc[field] = fieldData.defaultValue
        }
        return acc
      }, {})

      result.platform = defaultPlatform.value
      return result
    }

    const formState = reactive(getDefaultFormState())

    const formFields = computed(() => {
      const hasMultiUserIds = platformData.value.formFields.includes('userId2')

      return platformData.value.formFields.map((field) => {
        const fieldData = PARAMETER_FORM_FIELDS.find(({ id }) => id === field)

        let toolTip = fieldData.toolTip ? t(fieldData.toolTip) : ''
        switch (field) {
          case 'betAmount': {
            const { betAmount } = formState
            const { balanceMultiplier } = platformData.value

            toolTip = betAmount
              ? `${t('tip-bet-amount-with-balance')} ${(betAmount * balanceMultiplier).toFixed(2)}`
              : toolTip
            break
          }

          case 'userId1':
            toolTip = hasMultiUserIds ? t('tip-user-id-same-currency') : toolTip
            break

          case 'currency':
            toolTip = hasMultiUserIds ? t('tip-currency') : toolTip
            break

          default:
            break
        }

        return {
          ...fieldData,
          placeholder: fieldData.placeholder ? t(fieldData.placeholder) : '',
          toolTip,
        }
      })
    })

    const scenarioCheckOptions = computed(() => Object.entries(ALL_PLATFORM_TEST_SCENARIO_LIST[formState.platform])
      .map(([scenarioNo, { name: scenarioName }]) => ({ label: `${scenarioNo}. ${scenarioName}`, value: scenarioNo })))

    const skippedTestScenariosValidator = async (rule, value) => {
      if (value.length === scenarioCheckOptions.value.length) return Promise.reject(new Error(t('rule-skippedTestScenarios')))
      return Promise.resolve()
    }

    const formRules = computed(() => {
      const result = {}
      formFields.value.forEach(({ id, rules }) => {
        if (rules) result[id] = rules.map(({ message, ...rule }) => ({ ...rule, message: t(message) }))
        if (id === 'skippedTestScenarios') {
          result[id] = [{
            type: 'array',
            validator: skippedTestScenariosValidator,
          }]
        }
      })
      return result
    })

    const checkAll = computed(() => formState.skippedTestScenarios.length === scenarioCheckOptions.value.length)

    const onCheckChange = checked => formState.skippedTestScenarios = checked
    const onCheckAllChange = e => formState.skippedTestScenarios = e.target.checked ? scenarioCheckOptions.value.map(({ value }) => value) : []

    // const logTestedFormData = async (formData) => {
    //   const INTERNAL_URLS = [
    //     'http://localhost:8081/single-wallet-agent/api/callback',
    //     'http://awc-api-stg.eba-pxvmchq2.ap-northeast-1.elasticbeanstalk.com/single-wallet-agent/api/callback',
    //     // 'https://allwecan.info/single-wallet-agent/api/callback',
    //   ]

    //   if (!INTERNAL_URLS.some(url => formData.callbackUrl.startsWith(url))) {
    //     const data = { ...formData }
    //     delete data.skippedTestScenarios

    //     await logTestedClient(data)
    //   }
    // }

    const COUNTDOWN_SECONDS = 5
    const insufficientBalanceBetTestState = reactive({
      type: 'zero',
      modalVisible: false,
      isCounting: false,
      countDownSec: COUNTDOWN_SECONDS,
      confirmResolveFunc: () => {},
    })

    const finishedInsufficientBalanceBetTestModalVisible = ref(false)

    const onConfirmInsufficientBalanceBetTest = () => {
      insufficientBalanceBetTestState.isCounting = true

      for (let i = COUNTDOWN_SECONDS; i >= 0; i -= 1) {
        setTimeout(() => {
          insufficientBalanceBetTestState.countDownSec = i
          if (!i) {
            insufficientBalanceBetTestState.modalVisible = false
            insufficientBalanceBetTestState.confirmResolveFunc()
            insufficientBalanceBetTestState.countDownSec = COUNTDOWN_SECONDS
            insufficientBalanceBetTestState.isCounting = false
          }
        }, 1000 * (COUNTDOWN_SECONDS - i))
      }

      document.getElementById('test-report').scrollIntoView({ behavior: 'smooth' })
    }

    const onConfirmFinishedInsufficientBalanceBetTest = () => {
      finishedInsufficientBalanceBetTestModalVisible.value = false
    }

    const onSubmit = () => {
      formRef.value.validate()
        .then(async () => {
          document.getElementById('test-report').scrollIntoView({ behavior: 'smooth' })

          const formData = toRaw(formState)
          console.log('formData', formData)
          formData.betAmount = parseFloat(formData.betAmount)

          testData.isTesting = true
          testData.errorData = undefined
          testData.formData = formData

          const scenarioCounts = scenarioCheckOptions.value.length - formState.skippedTestScenarios.length
          testData.scenarioCounts = scenarioCounts
          testData.testedScenarioCounts = 0

          const ts = new platformTestScenarios[formData.platform](formData)

          ts.emitTestedScenarioCounts = testedScenarioCounts => testData.testedScenarioCounts = testedScenarioCounts

          ts.insufficientBalanceBetTestPromise = (type) => {
            insufficientBalanceBetTestState.modalVisible = true
            insufficientBalanceBetTestState.type = type
            return new Promise((resolve) => {
              insufficientBalanceBetTestState.confirmResolveFunc = resolve
            })
          }

          ts.finishInsufficientBalanceBetTest = () => { finishedInsufficientBalanceBetTestModalVisible.value = true }

          try {
            await ts.runTestScenarios()
            // logTestedFormData(formData)
          } catch (e) {
            // FetchError / ContentTypeError / AbortError
            if (!Array.isArray(e)) testData.errorData = e

            console.error(e)
            // logTestedFormData(formData)
          }

          const testReportData = ts.getTestReportData()

          testData.platformData = { ...platformData.value }
          testData.formData = { ...formData }
          testData.testReportData = testReportData

          insufficientBalanceBetTestState.countDownSec = COUNTDOWN_SECONDS
          setTimeout(() => testData.isTesting = false, 500)
        })
        .catch((error) => {
          console.error('error', error, 'formState', toRaw(formState))
        })
    }

    const onPlatformChange = value => defaultPlatform.value = value

    const onBetAmountBlur = e => formState.betAmount = e.target.value ? parseFloat(e.target.value).toFixed(2) : e.target.value

    watch(defaultPlatform, () => {
      const newFormState = getDefaultFormState()
      Object.keys(formState).forEach((key) => {
        if (!Object.prototype.hasOwnProperty.call(newFormState, key)) {
          delete formState[key]
        } else if (!['platform', 'skippedTestScenarios'].includes(key) && formState[key]) {
          newFormState[key] = formState[key]
        }
      })

      Object.assign(formState, newFormState)
    })

    // const resetForm = () => {
    //   formRef.value.resetFields()
    // }

    return {
      t,
      env: process.env.NODE_ENV,
      actionsCollapseActiveKey,
      skippedScenariosCollapseActiveKey,
      formRef,
      formState,
      formRules,
      formFields,
      onSubmit,
      platformData,
      platformOptions: PLATFORM_LIST.map(({ id, name }) => ({ label: name, value: id })),
      scenarioCheckOptions,
      checkAll,
      onCheckChange,
      onCheckAllChange,
      onPlatformChange,
      onBetAmountBlur,
      // resetForm,
      insufficientBalanceBetTestState,
      onConfirmInsufficientBalanceBetTest,
      finishedInsufficientBalanceBetTestModalVisible,
      onConfirmFinishedInsufficientBalanceBetTest,
    }
  },
}
</script>

<style>
.ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-explain, .ant-form-item-extra {
  font-size: 12px;
}

.ant-form-item-label {
  white-space: normal;
  overflow: visible;
}

.ant-row.ant-form-item {
  align-items: center;
}

.ant-input-number {
  width: 100%;
}

.actions-collapse.ant-collapse {
  border-color: #fafafa;
  margin-bottom: 20px;
}

.skipped-scenarios-collapse.ant-collapse {
  border: 0;
  background: none;
}

.actions-collapse.ant-collapse > .ant-collapse-item {
  border-color: #fafafa;
}

.actions-collapse .ant-collapse-content, .skipped-scenarios-collapse .ant-collapse-content, .skipped-scenarios-collapse > .ant-collapse-item {
  border: 0;
}

.actions-collapse .ant-collapse-content-box {
  padding: 8px 16px;
}

.actions-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 16px 8px 40px;
}

.actions-collapse .ant-collapse-content-box {
  padding: 8px 0;
}

.action-list {
  margin: 0;
}

.skipped-scenarios-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 16px 0 20px;
}

.skipped-scenarios-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0;
}

.skipped-scenarios-collapse .ant-collapse-content-box {
  padding: 8px 0 0;
}

.skipped-scenarios-check-all {
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #ddd;
}

.skipped-scenarios-checkbox {
  width: 100%;
}

.skipped-scenarios-checkbox .ant-checkbox-wrapper {
  width: 100%;
  margin-bottom: 8px;
}

.skipped-scenarios-checkbox .ant-checkbox {
  top: 4px;
  vertical-align: top;
}

.skipped-scenarios-checkbox .ant-checkbox + span {
  display: inline-block;
  width: calc(100% - 20px);
  padding-right: 0;
}
</style>
