export default [
  {
    name: 'Bet > Settle Win',
    functionName: 'bet_settleWin',
  },
  {
    name: 'Bet > Settle Lose',
    functionName: 'bet_settleLose',
  },
  {
    name: 'Bet > Settle Win 5 times',
    functionName: 'bet_settleWin5Times',
  },
  {
    name: 'Bet > (Network) Settle Win 5 times',
    functionName: 'bet_asyncSettleWin5Times',
  },
  {
    name: 'Bet > CancelBet',
    functionName: 'bet_cancelBet',
  },
  {
    name: 'Bet > CancelBet 5 times',
    functionName: 'bet_cancelBet5Times',
  },
  {
    name: 'CancelBet only',
    functionName: 'cancelBetOnly',
  },
  {
    name: 'CancelBet > Bet',
    functionName: 'cancelBet_bet',
  },
  {
    name: 'Place 3 bets in 3 rounds > CancelBet',
    functionName: 'bet1_bet2_bet3_cancelBet123',
  },
  {
    name: 'Bonus - BetNSettle Win',
    functionName: 'betNSettleWin',
  },
  {
    name: 'Bonus - BetNSettle Win 5 times',
    functionName: 'betNSettleWin5Times',
  },
  {
    name: 'Bonus - (Network) BetNSettle Win 5 times',
    functionName: 'asyncBetNSettleWin5Times',
  },
  {
    name: 'Positive Balance - Bet',
    functionName: 'positiveBalance_bet',
  },
  {
    name: 'Zero Balance - Bet',
    functionName: 'zeroBalance_bet',
  },
  {
    name: 'Negative Balance - Bet',
    functionName: 'negativeBalance_bet',
  },
]
