import PlatformActions from './PlatformActions'

export default class SKYCASINOActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnDataByGame = {
      baccarat: {
        gameName: 'Baccarat',
        gameCode: 'SKYCASINO-LIVE-001',
        txnIdPrefix: 'BAC',
      },
      roulette: {
        gameName: 'Roulette',
        gameCode: 'SKYCASINO-LIVE-002',
        txnIdPrefix: 'RL',
      },
      oneday: {
        gameName: '1 Day Teen Patti',
        gameCode: 'SKYCASINO-LIVE-005',
        txnIdPrefix: 'TPO',
      },
    }

    this.constantGameInfoByGame = {
      baccarat: {
        streamerId: '',
        tableId: '301',
        dealerDomain: 'Mexico',
      },
      roulette: {
        tableId: '371',
        dealerDomain: 'Mexico',
      },
      oneday: {
        tableId: '391',
        dealerDomain: 'Mexico',
      },
    }
  }

  constantTxnData(game = 'baccarat') {
    return {
      gameType: 'LIVE',
      // gameName: this.constantTxnDataByGame[game], // cancelBet and cancelAdjustAmt do NOT have this field
      gameCode: this.constantTxnDataByGame[game].gameCode,
      platform: 'SKYCASINO',
    }
  }

  getPlatformTxIdAndRoundId({ txnId, roundId, game }) {
    const { txnIdPrefix } = this.constantTxnDataByGame[game]
    const { dealerDomain, tableId } = this.constantGameInfoByGame[game]
    const tableIdInRound = tableId.padStart(2, '0')

    return {
      platformTxId: `${txnIdPrefix}-${txnId}`, // BAC-8511001601
      roundId: `${dealerDomain}-${tableIdInRound}-GA${roundId}`, // Mexico-01-GA262720036
    }
  }

  getBetMessage(txnList) {
    const { currency, userIdPrefix } = this.formData

    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betType = 'Banker',
      betTime,
      betAmount,
      roundStartTime,
      denyTime,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      betType,
      currency,
      betTime,
      betAmount,
      gameInfo: {
        roundStartTime,
        ...this.constantGameInfoByGame[game],
        denyTime,
      },
      isPremium: false,
    }))

    if (this.summaryReqId) this.summaryReqId += 1

    const message = {
      action: 'bet',
      ...(this.summaryReqId && {
        requestId: `${this.summaryReqId}`,
        refId: `${userIdPrefix}${txns[0].userId}${txns[0].roundId}`,
        amount: txns.reduce((acc, { betAmount }) => acc += betAmount, 0), // eslint-disable-line no-param-reassign
      }),
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    if (this.summaryReqId && !this.isAsync) this.summaryReqId += 1

    const message = {
      action: 'settle',
      ...(this.summaryReqId && {
        requestId: `${this.summaryReqId}`,
        refId: `${userIdPrefix}${txns[0].userId}${txns[0].roundId}`,
        amount: txns.reduce((acc, { winAmount }) => acc += winAmount, 0), // eslint-disable-line no-param-reassign
      }),
      txns,
    }

    return JSON.stringify(message)
  }

  // getUnsettleMessage(txnList) {
  //   const txns = txnList.map(({
  //     game = 'baccarat',
  //     userId,
  //     txnId,
  //     roundId,
  //     result,
  //     betAmount,
  //     updateTime,
  //     roundStartTime,
  //     winner,
  //     odds,
  //     winLoss,
  //     status,
  //   }) => ({
  //     ...this.constantTxnData(game),
  //     gameName: this.constantTxnDataByGame[game].gameName,
  //     userId,
  //     ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
  //     updateTime,
  //     betAmount,
  //     gameInfo: {
  //       ...this.constantGameInfoByGame[game],
  //       result,
  //       roundStartTime,
  //       winner,
  //       ip: '172.168.0.1',
  //       odds,
  //       winLoss,
  //       status,
  //     },
  //   }))

  //   const message = {
  //     action: 'unsettle',
  //     txns,
  //   }

  //   return JSON.stringify(message)
  // }

  getResettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      updateTime,
      roundId,
      betType = 'Banker',
      betTime,
      turnover,
      betAmount,
      winAmount,
      result,
      roundStartTime,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betType,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    const message = {
      action: 'resettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidBetMessage(txnList) {
    const { userIdPrefix } = this.formData

    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
      roundStartTime,
      result,
      winner,
      odds,
      winLoss,
      status,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      betAmount,
      updateTime,
      voidType,
      gameInfo: {
        ...this.constantGameInfoByGame[game],
        result,
        roundStartTime,
        winner,
        ip: '172.168.0.1',
        odds,
        winLoss,
        status,
      },
    }))

    if (this.summaryReqId) this.summaryReqId += 1

    const message = {
      action: 'voidBet',
      ...(this.summaryReqId && {
        requestId: `${this.summaryReqId}`,
        refId: `${userIdPrefix}${txns[0].userId}${txns[0].roundId}`,
      }),
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
      voidType,
    }) => ({
      ...this.constantTxnData(game),
      gameName: this.constantTxnDataByGame[game].gameName,
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      updateTime,
      voidType,
      betAmount,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      game = 'baccarat',
      userId,
      txnId,
      roundId,
      roundStartTime,
    }) => ({
      ...this.constantTxnData(game),
      userId,
      ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
      gameInfo: {
        roundStartTime,
        ...this.constantGameInfoByGame[game],
      },
    }))

    if (this.summaryReqId) this.summaryReqId += 1

    const message = {
      action: 'cancelBet',
      ...(this.summaryReqId && {
        requestId: `${this.summaryReqId}`,
        refId: `${this.summaryRefId}`,
      }),
      txns,
    }

    return JSON.stringify(message)
  }

  // getGiveMessage(txnList) {
  //   const { currency } = this.formData

  //   const txns = txnList.map(({
  //     userId,
  //     betAmount,
  //     promotionTxId,
  //     txTime,
  //   }) => ({
  //     platform: this.constantTxnData().platform,
  //     currency,
  //     userId,
  //     amount: betAmount, // betAmount.toFixed(1)
  //     promotionTxId: `W${promotionTxId}`, // ex: W2626000000014
  //     promotionId: '336',
  //     promotionTypeId: '3',
  //     txTime,
  //   }))

  //   const message = {
  //     action: 'give',
  //     txns,
  //   }

  //   return JSON.stringify(message)
  // }

  // getAdjustAmtMessage(txnList) {
  //   const { currency } = this.formData

  //   const txns = txnList.map(({
  //     game = 'oneday',
  //     userId,
  //     txnId,
  //     adjustId,
  //     roundId,
  //     betTime,
  //     adjustAmount,
  //     roundStartTime,
  //     denyTime,
  //   }) => {
  //     const { txnIdPrefix } = this.constantTxnDataByGame[game]

  //     return {
  //       ...this.constantTxnData(game),
  //       gameName: this.constantTxnDataByGame[game].gameName,
  //       userId,
  //       ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
  //       refPlatformTxId: `${txnIdPrefix}-${txnId}-${adjustId}`,
  //       betType: null,
  //       currency,
  //       betTime,
  //       adjustAmount,
  //       gameInfo: {
  //         roundStartTime,
  //         ...this.constantGameInfoByGame[game],
  //         denyTime,
  //       },
  //     }
  //   })

  //   const message = {
  //     action: 'adjustAmt',
  //     txns,
  //   }

  //   return JSON.stringify(message)
  // }

  // getCancelAdjustAmtMessage(txnList) {
  //   const txns = txnList.map(({
  //     game = 'oneday',
  //     userId,
  //     txnId,
  //     adjustId,
  //     roundId,
  //     roundStartTime,
  //     denyTime,
  //   }) => {
  //     const { txnIdPrefix } = this.constantTxnDataByGame[game]

  //     return {
  //       ...this.constantTxnData(game),
  //       userId,
  //       ...this.getPlatformTxIdAndRoundId({ txnId, roundId, game }),
  //       refPlatformTxId: `${txnIdPrefix}-${txnId}-${adjustId}`,
  //       gameInfo: {
  //         roundStartTime,
  //         ...this.constantGameInfoByGame[game],
  //         denyTime,
  //       },
  //     }
  //   })

  //   const message = {
  //     action: 'cancelAdjustAmt',
  //     txns,
  //   }

  //   return JSON.stringify(message)
  // }
}
