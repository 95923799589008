<template>
  <AntTable
    :rowKey='(record) => `scenario${record.index}`'
    :columns="scenarioColumns"
    :data-source="data"
    :pagination="false"
    :expandedRowKeys="expandedRowKeys.scenario"
    @expandedRowsChange="onExpandedRowsChange"
  >
   <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'passed'">
        <v-switch :case="getScenarioResult(record).toString()">
          <template #warning>
            <CheckCircleIcon class="scenario-result-icon warning" />{{ t('pass') }}
          </template>
          <template #true>
            <CheckCircleIcon class="scenario-result-icon" />{{ t('pass') }}
          </template>
          <template #false>
            <CloseCircleIcon class="scenario-result-icon error" />{{ t('fail') }}
          </template>
        </v-switch>
      </template>
    </template>

    <template #expandedRowRender="{ record, index }">
      <slot name="expandedRowRender" :record="record" :index="index"></slot>
    </template>
  </AntTable>
</template>

<script>
import {
  inject,
  reactive,
  computed,
} from 'vue'

import VSwitch from '@lmiller1990/v-switch'

import { Table as AntTable } from 'ant-design-vue'

import CheckCircleIcon from '@ant-design/icons-vue/lib/icons/CheckCircleOutlined'
import CloseCircleIcon from '@ant-design/icons-vue/lib/icons/CloseCircleOutlined'

export default {
  name: 'TestScenarioTable',
  components: {
    VSwitch,
    AntTable,
    CheckCircleIcon,
    CloseCircleIcon,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    expandedRowKeys: Object,
  },
  setup(props) {
    const t = inject('t')
    const expandedRowKeys = reactive(props.expandedRowKeys)

    const scenarioColumns = computed(() => [
      {
        title: t('test-result'),
        key: 'scenarioPassed',
        dataIndex: 'passed',
        width: 120,
      },
      {
        title: t('test-scenario'),
        dataIndex: 'scenario',
        key: 'scenario',
      },
    ])

    const onExpandedRowsChange = rowKeys => expandedRowKeys.scenario = rowKeys

    const getScenarioResult = ({ passed, requests }) => {
      const haveWarning = requests.some(({ tests }) => tests.some(({ warning }) => !!warning))

      // eslint-disable-next-line no-nested-ternary
      return passed
        ? haveWarning ? 'warning' : passed
        : passed
    }

    return {
      t,
      scenarioColumns,
      onExpandedRowsChange,
      getScenarioResult,
    }
  },
}
</script>

<style>
.anticon.scenario-result-icon {
  font-size: 16px;
  color: #52c41a;
  margin-right: 8px;
  display: inline-block;
}

.anticon.scenario-result-icon.error {
  color: #ff4d4f;
}

.anticon.scenario-result-icon.warning {
  color: #faad14;
}
</style>
